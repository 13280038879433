import { useAuth0 } from '@auth0/auth0-react';
import {
  MainNavbar,
  NavbarLayout as TuxedoNavbarLayout,
} from '@insidedesk/tuxedo';
import { NAVBAR_CONFIG } from 'config';
import { PropsWithChildren } from 'react';

export default function NavbarLayout({ children }: PropsWithChildren) {
  const { logout } = useAuth0();
  return (
    <TuxedoNavbarLayout
      navbar={
        <MainNavbar
          active='credentials'
          config={{
            iq: { href: NAVBAR_CONFIG.iqUrl },
            assist: { href: NAVBAR_CONFIG.assistUrl },
            credentials: { href: NAVBAR_CONFIG.credentialsUrl },
            users: { href: NAVBAR_CONFIG.usersUrl },
            remit: { href: NAVBAR_CONFIG.remitUrl },
            posting: { href: NAVBAR_CONFIG.postingUrl },
          }}
          helpLink={NAVBAR_CONFIG.helpUrl}
          logout={() => logout({ returnTo: window.location.origin })}
          showBlurToggle
        />
      }
    >
      {children}
    </TuxedoNavbarLayout>
  );
}
