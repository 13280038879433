export const COMMON_CONFIG = {
  stageId: process.env.REACT_APP_ANALYTICS_STAGE_ID,
};

export const AUTH0_CONFIG = {
  audience: 'https://client-api.insidedesk.com',
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
  domain: `insidedesk-${process.env.REACT_APP_AUTH0_TENANT}.us.auth0.com`,
  cookieDomain: `insidedesk-${process.env.REACT_APP_AUTH0_TENANT}.us.auth0.com`,
};

export const PENDO_CONFIG = {
  stageId: process.env.REACT_APP_ANALYTICS_STAGE_ID ?? '',
};

export const FULL_STORY_CONFIG = {
  orgId: process.env.REACT_APP_FULL_STORY_ORG_ID,
};

export const NAVBAR_CONFIG = {
  assistUrl: process.env.REACT_APP_ASSIST_PAGE ?? '',
  credentialsUrl: process.env.REACT_APP_CREDENTIALS_PAGE ?? '',
  iqUrl: process.env.REACT_APP_IQ_PAGE ?? '',
  remitUrl: process.env.REACT_APP_REMIT_PAGE ?? '',
  usersUrl: process.env.REACT_APP_USERS_PAGE ?? '',
  helpUrl: process.env.REACT_APP_HELP_URL ?? '',
  postingUrl: process.env.REACT_APP_POSTING_PAGE ?? '',
};
